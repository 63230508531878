import api from '@/services/api/api';

export const useFilmsMaterials = (count) => {
  const route = useRoute();
  const queryKey = computed(() => ['filmsMaterials', route.fullPath]);

  const filmsMaterials = useQuery(
    queryKey,
    async () => {
      const { data } = await api.lectures.getRandomVideoMaterials(
        toValue(count),
      );
      return data;
    },
    {
      staleTime: Infinity,
      select: ({ data }) => data,
    },
  );

  onServerPrefetch(async () => {
    await filmsMaterials.suspense();
  });

  return {
    filmsMaterials,
  };
};
